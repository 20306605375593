<template>
  <div class="container main cf">
    <h2>在线留言</h2>
    <div class="main_left">
      <a href="#">营销门户 </a>
      <a href="#">电商零售</a>
      <a href="#">门店服务 </a>
      <a href="#">营销推广 </a>
      <a href="#">设计服务</a>
      <a href="#">客户运营</a>
      <img src="@/assets/images/03_f0hi.jpg" />
    </div>
    <div class="main_right">
      <h4>您可以留下您宝贵的意见，我们会在第一时间回复您，谢谢关注！</h4>
      <form action="#">
        <p>您的姓名<span>*</span></p>
        <input type="text" class="input" />
        <p>您的电话<span>*</span></p>
        <input type="tel" class="input" />
        <p>您的邮箱<span>*</span></p>
        <input type="email" class="input" />
        <p>咨询项目<span>*</span></p>
        <input type="checkbox" />床上用品 <input type="checkbox" />家居饰品
        <input type="checkbox" />居家日用 <input type="checkbox" />家居收纳
        <input type="checkbox" />厨房收纳 <input type="checkbox" />厨房餐饮
        <p>咨询内容<span>*</span></p>
        <textarea cols="100" rows="10"></textarea>
        <button type="submit">提交</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.main {
  margin-bottom: 16px;
}
/* 左边 */
.main_left {
  float: left;
  width: 261px;
  margin-right: 28px;
}
.main_left a {
  font-size: 12px;
  width: 261px;
  height: 48px;
  display: inline-block;
  text-align: center;
  line-height: 48px;
  background: #f2f2f2;
  margin-bottom: 2px;
}
.main_left img {
  width: 261px;
  margin-top: 20px;
}
/* 右边 */
.main_right {
  float: right;
  width: 910px;
}
.main_right h4 {
  font-size: 14px;
}
.main_right span {
  color: #f00;
}
.main_right .input,
.main_right textarea {
  width: 800px;
  height: 41px;
  box-sizing: border-box;
  border: 3px solid #e1e1e1;
  background: #fff;
  outline: 0;
}
.main_right p {
  margin: 27px 0 14px 0;
}
.main_right textarea {
  height: 73px;
}
.main_right button {
  color: #fff;
  background: #21bcd8;
  width: 800px;
  height: 45px;
  outline: 0;
  border: 0;
  margin: 26px 0 98px 0;
  cursor: pointer;
}
</style>